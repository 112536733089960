import {Currency, ETHER, Token} from 'sdk'
import {BinanceIcon} from 'uikit'
import {useMemo} from 'react'
import styled from 'styled-components'
import useHttpLocations from '../../hooks/useHttpLocations'
import {WrappedTokenInfo} from '../../state/lists/hooks'
import getTokenLogoURL from '../../utils/getTokenLogoURL'
import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({size}) => size};
  height: ${({size}) => size};
  border-radius: 50%;
`
const StyleLogoImg = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
`

export default function CurrencyLogo({
     currency,
     size = '24px',
     style,
 }: {
    currency?: Currency
    size?: string
    style?: React.CSSProperties
}) {
    const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

    const srcs: string[] = useMemo(() => {
        // if (currency === ETHER) return []
        // console.log('Logo------', currency);
        if (currency instanceof Token) {
            if (currency instanceof WrappedTokenInfo) {
                return [...uriLocations, getTokenLogoURL(currency.address)]
            }
            return [getTokenLogoURL(currency.address)]
        }
        return []
    }, [currency, uriLocations])

    if (currency === ETHER) {
        return <StyleLogoImg src={getTokenLogoURL('pg-logo')} alt="" style={style}></StyleLogoImg>
        // return <BinanceIcon width={size} style={style}/>
    }
    return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style}/>
}
