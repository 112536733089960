import { memo } from 'react'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, LinkExternal, Flex, Svg,  Button } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import ico1 from '../../../public/images/ico1.png'
import Image from 'next/image'

const Wrapper = memo(styled.div<{ $isSide: boolean }>`
  width: 100%;
  height: ${({ $isSide }) => ($isSide ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  padding-right: ${({ $isSide }) => ($isSide ? '32px' : '0px')};
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: center;
    flex-direction: ${({ $isSide }) => ($isSide ? 'column' : 'row')};
  }
`)

const BubbleWrapper = styled(Flex)`
  svg {
    fill: ${({ theme }) => theme.colors.textSubtle};
    transition: background-color 0.2s, opacity 0.2s;
  }
  &:hover {
    svg {
      opacity: 0.65;
    }
  }
  &:active {
    svg {
      opacity: 0.85;
    }
  }
`
const ImageWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  height: fit-content;
  magin:0 auto;
  span {
    border-radius: ${({ theme }) => theme.radii.default};
    margin: 5px 10px !important;
  }
`

const StyledNav = styled.nav`
margin-top:27px;
margin-bottom: 40px;
font-size: 12px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #C5C5C5;
line-height: 17px;
`

type FooterVariant = 'default' | 'side'

const Footer: React.FC<{ variant?: FooterVariant; helpUrl?: string }> = ({
  variant = 'default',
  helpUrl = EXCHANGE_DOCS_URLS,
}) => {
  const { t } = useTranslation()
  const isSide = variant === 'side'
  return (
    <Wrapper $isSide={isSide}>
      <Flex flexDirection={isSide ? 'column' : ['column', 'column', 'column']} alignItems="center"  width={['100%', '100%', '100%', isSide ? '100%' : 'auto']}>
      <ImageWrapper>
        <Image src="/images/ico1.png"  width={38} height={43} onClick={() => {window.open('https://twitter.com/W3SWAP')}}/>
        <Image src="/images/ico2.png"  width={38} height={43} onClick={() => {window.open('https://t.me/W3_Global')}}/>
        <Image src="/images/ico3.png"  width={38} height={43} onClick={() => {window.open('https://medium.com/@w3swap')}}/>
        <Image src="/images/ico4.png"  width={38} height={43} onClick={() => {window.open('https://w3swap.gitbook.io')}}/>
        </ImageWrapper>
        <StyledNav>Copyright © 2023 W3SWAP — All rights reserved</StyledNav>
      </Flex>
      {/* {isSide && <Flex flexGrow={1} />} */}
      {/* <Flex
        flexGrow={isSide ? 0 : 1}
        alignItems="center"
        width={['100%', '100%', '100%', isSide ? '100%' : 'auto']}
        justifyContent={['center', 'center', 'center', 'flex-end']}
      >
        <BubbleWrapper>
          <Button id="clickExchangeHelp" as="a" external href={helpUrl} variant="subtle">
            {t('Need help ?')}
          </Button>
          <Svg viewBox="0 0 16 16">
            <path d="M0 16V0C0 0 3 1 6 1C9 1 16 -2 16 3.5C16 10.5 7.5 16 0 16Z" />
          </Svg>
        </BubbleWrapper>
        <Image src="/images/help.png" alt="Get some help" width={160} height={108} />
      </Flex> */}
      
    </Wrapper>
  )
}

export default memo(Footer)
